import React from 'react';

export default function ChevronIcon({ open, rotate }) {
	return (
		<>
			&nbsp;
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 6.187 10.497"
				width="6.187"
				height="10.497"
				fill="#89a112"
				className={rotate ? `rotate ${open}` : ''}
			>
				<path
					d="M9.272,15.306l3.645-3.645L9.272,8.017A.937.937,0,0,1,10.6,6.692L14.908,11a.935.935,0,0,1,0,1.324L10.6,16.64a.935.935,0,0,1-1.324,0A.956.956,0,0,1,9.272,15.306Z"
					transform="translate(-8.997 -6.417)"
				/>
			</svg>
			<style jsx>{`
				svg.rotate {
					transform: rotate(90deg);
					transition: all 0.25s ease;
					margin-left: 5px;
					margin-bottom: -2px;
				}
				svg.rotate.true {
					transform: rotate(-90deg);
					transition: all 0.25s ease;
				}
			`}</style>
		</>
	);
}
