import React from 'react';

export default function HamburgerButton({ openNavMobil, openNavMobilClick }) {
	return (
		<>
			<div
				className="hamburger-container"
				onKeyUp={(e) => {
					e.preventDefault();
					if (e.keyCode === 13) {
						openNavMobilClick;
					}
				}}
				onClick={openNavMobilClick}
				aria-label="Menu"
				role="button"
				aria-expanded={openNavMobil}
				tabIndex="0"
			>
				<div className={`hamburger-toggle-button ${openNavMobil ? 'menu-open-button' : ''}`}>
					<div className="line" />
					<div className="line" />
					<div className="line" />
				</div>
			</div>

			<style jsx>{`
				.hamburger-toggle-button {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: flex-start;
					cursor: pointer;
					width: 100%;
					height: 100%;
				}
				.hamburger-toggle-button .line {
					transition: all 0.2s ease-in-out;
					margin: 3px 0;
					height: 3px;
					width: 30px;
					border-radius: 5px;
					background-color: #89a112;
				}
				.hamburger-toggle-button .line:nth-child(1) {
					width: 30px;
				}
				.hamburger-toggle-button .line:nth-child(2) {
					width: 30px;
				}
				.hamburger-toggle-button .line:nth-child(3) {
					width: 30px;
				}
				.menu-open-button .line {
					transition: all 0.2s ease-in-out;
				}
				.menu-open-button .line:nth-child(1) {
					transform: translateY(9px) rotate(45deg);
				}
				.menu-open-button .line:nth-child(2) {
					transform: scale(0);
				}
				.menu-open-button .line:nth-child(3) {
					transform: translateY(-9px) rotate(-45deg);
					width: 30px;
				}
			`}</style>
		</>
	);
}
