import React, { useState } from 'react';

export default function Contact() {
	return (
		<>
			<h2>Nous contacter</h2>
			<form name="contact" method="POST" data-netlify="true" action="/success">
				<input type="hidden" name="form-name" value="contact" />
				<fieldset className="radio-wrapper">
					<label className="radio-label">
						<input name="civilities" type="radio" value="Madame" checked={true} />
						<span className="custom-radio-checkmark" />
						Madame
					</label>
					<label className="radio-label">
						<input name="civilities" type="radio" value="Monsieur" />
						<span className="custom-radio-checkmark" />
						Monsieur
					</label>
				</fieldset>
				<label>
					Société *
					<input type="text" name="societe" required />
				</label>
				<label>
					Prénom
					<input type="text" name="prenom" />
				</label>
				<label>
					Nom *
					<input type="text" name="nom" required />
				</label>
				<label>
					Fonction
					<input type="text" name="fonction" />
				</label>
				<label>
					Adresse mail *
					<input type="email" name="mail" required />
				</label>
				<label>
					Téléphone *
					<input type="tel" name="telephone" pattern="[0-9]{10}" required />
				</label>
				<label>
					Message
					<textarea name="message" required />
				</label>
				<div className="action-button-wrapper">
					<button type="submit" className="back-btn">
						Envoyer
					</button>
				</div>
			</form>
			<p>* champs obligatoires, merci</p>
			<style jsx>{`
				label {
					display: block;
					width: 100%;
				}
				input,
				textarea {
					width: 100%;
					border-radius: 4px;
					box-shadow: none;
					margin-bottom: 15px;
					min-height: 25px;
					border: 1px solid #89a112;
					padding: 5px;
					box-sizing: border-box;
					font-size: 16px;
				}
				textarea {
					min-height: 200px;
					margin-bottom: 30px;
					padding: 5px;
					box-sizing: border-box;
					font-size: 16px;
					font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
						Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
				}
				.action-button-wrapper {
					text-align: center;
				}
				h2 {
					color: ;
				}
				.radio-wrapper {
					border: none;
					display: flex;
					align-items: center;
					justify-content: space-around;
					margin: 15px 0;
				}
				.radio-label {
					display: inline-flex;
					width: auto;
				}
				.radio-label input {
					width: auto;
				}

				.radio-label {
					position: relative;
					display: flex;
					align-items: center;
					padding-left: 30px;
					margin: 10px 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				.radio-label input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					width: auto;
				}
				.custom-radio-checkmark {
					position: absolute;
					top: 0;
					left: 0;
					height: 20px;
					width: 20px;
					background-color: white;
					border-radius: 50%;
					border: 1px solid #89a112;
				}
				.radio-label:hover input ~ .custom-radio-checkmark {
					background: rgba(0, 0, 0, 0.1);
				}
				.radio-label input:checked ~ .custom-radio-checkmark {
				}
				.custom-radio-checkmark:after {
					content: '';
					position: absolute;
					display: none;
				}

				.radio-label input:checked ~ .custom-radio-checkmark:after {
					display: block;
				}

				.radio-label .custom-radio-checkmark:after {
					top: 3px;
					left: 3px;
					width: 14px;
					height: 14px;
					border-radius: 50%;
					background: #89a112;
				}
			`}</style>
		</>
	);
}
