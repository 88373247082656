import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import PopUp from '../commons/PopUp';
import Contact from '../form/Contact';

function Footer({ data }) {
	const { frontmatter } = data.markdownRemark;

	return (
		<>
			<footer className="large-contain">
				<p>
					Tous droits réservés ECEO -{' '}
					<Link to="/mentions-legales" className="footer-a" title="Mentions légales du site ECEO">
						Mentions légales
					</Link>
				</p>
				<div className="buttons-wrapper">
					<Link to="/contact" className="button back-btn">
						Contact
					</Link>
					<p className="button border-btn">{frontmatter.informationBloc.phone}</p>
				</div>
			</footer>
			<style jsx>{`
				footer {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.buttons-wrapper {
					width: 280px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				@media (max-width: 750px) {
					flex-direction: column;
				}
			`}</style>
		</>
	);
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query Footer {
				markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
					frontmatter {
						informationBloc {
							phone
						}
					}
				}
			}
		`}
		render={(data) => <Footer data={data} {...props} />}
	/>
);
