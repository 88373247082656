import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta({
	type = 'website',
	title = 'ECEO',
	description = 'ECEO',
	img = '/img/logo.png',
	robots = true,
}) {
	const url = typeof window !== 'undefined' ? window.location.href : '';

	return (
		<Helmet defer={false} defaultTitle={title} titleTemplate={`${title}`}>
			<meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />
			<html lang="fr" />
			<meta charSet="UTF-8" />
			<meta name="robots" content={robots ? 'index, follow' : 'noindex, nofollow'} />
			<link rel="canonical" href={`${url}`} />

			<meta property="og:site_name" content="ECEO" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="og:type" content={type} />

			<title>{title}</title>

			<meta property="og:title" content={title} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:image:alt" content={title} />

			<meta name="description" content={description} />
			<meta property="og:description" content={description} />
			<meta name="twitter:description" content={description} />

			<meta property="og:image" content={`https://www.eceo.fr/${img}`} />
			<meta property="og:image:alt" content={title} />
			<meta property="twitter:image" content={`https://www.eceo.fr/${img}`} />

			<meta property="og:url" content={url} />
			<script type="application/ld+json">
				{`
					{
						"@context": "https://schema.org",
						"@type": "Organization",
						"name": "ECEO",
						"description": "${description}",
						"url": "${url}",
						"logo": "https://www.l-1multiservices.com/img/logo.png",
					}
        `}
			</script>
		</Helmet>
	);
}
