import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Header from '../navigations/Header';
import Footer from '../navigations/Footer';
import Meta from '../meta/Meta';
import favicon from '../../img/favicon.png';
import { Helmet } from 'react-helmet';

function Layout({ title, description, children, data, index, robots = true }) {
	const { frontmatter } = data.markdownRemark;

	return (
		<>
			<Helmet>
				<link rel="icon" href={favicon} />
			</Helmet>
			<Meta title={title} description={description} robots={robots} />
			<Header
				phone={frontmatter.informationBloc.phone}
				linkedin={frontmatter.informationBloc.linkedin}
				activitiesList={frontmatter.activitiesList}
				index={index}
			/>
			<main>{children}</main>
			<Footer />
			<style jsx global>{`
				 {
					/* .activity-bloc-wrapper {
					position: relative;
					width: 100%;
					padding: 20px 0;
				}
				.activity-bloc-wrapper.img-left,
				.activity-bloc-wrapper.img-right {
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					justify-content: space-between;
				}
				.activity-bloc-wrapper.img-right {
					flex-flow: row-reverse wrap;
				}
				.activity-bloc-wrapper.img-bottom {
					display: flex;
					flex-direction: column-reverse;
					align-items: center;
				}
				.activity-bloc-wrapper.img-left picture,
				.activity-bloc-wrapper.img-left .markdown,
				.activity-bloc-wrapper.img-right picture,
				.activity-bloc-wrapper.img-right .markdown {
					position: relative;
					width: 100%;
				}
				.activity-bloc-wrapper.img-left img,
				.activity-bloc-wrapper.img-right img {
					width: 100%;
				}
				.activity-bloc-wrapper.img-top img,
				.activity-bloc-wrapper.img-bottom img {
					width: 100%;
					margin: 20px 0;
				}

				.markdown h2 {
					font-size: 20px;
					color: #89a112;
				}
				.markdown h3 {
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 0;
				}
				.markdown h3 ~ p {
					margin-top: 0;
				}
				.markdown ul {
					list-style: none;
					padding: 0;
					margin: 0;
				}
				.markdown ul li,
				.markdown ul li p {
					padding: 0;
					margin: 0;
				}
				.markdown ul li p {
					display: inline-flex;
				}
				.markdown ul li p:before {
					content: '•';
					color: #89a112;
					margin-right: 5px;
					font-size: 40px;
					line-height: 16px;
				}

				.activity-bloc-wrapper.green .markdown {
					color: white;
					background: #89a112;
					border-radius: 20px;
					padding: 20px;
					box-sizing: border-box;
				}
				.activity-bloc-wrapper.green .markdown h2 {
					color: white;
				}
				.activity-bloc-wrapper.green .markdown ul li p:before {
					color: white;
				}

				@media (min-width: 1000px) {
					.activity-bloc-wrapper.img-left picture,
					.activity-bloc-wrapper.img-left .markdown,
					.activity-bloc-wrapper.img-right picture,
					.activity-bloc-wrapper.img-right .markdown {
						width: 48%;
					}
				} */
				}
			`}</style>
		</>
	);
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query LayoutInformation {
				markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
					frontmatter {
						informationBloc {
							phone
							linkedin
						}
						activitiesList
					}
				}
			}
		`}
		render={(data) => <Layout data={data} {...props} />}
	/>
);
