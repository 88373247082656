import React, { useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import LogoAnimation from './IconAnimeLogo';
import slugify from 'react-slugify';
import ChevronIcon from '../commons/IconChevron';
import HamburgerButton from './HamburgerButton';
import logo from '../../img/logo-eceo.png';
import PopUp from '../commons/PopUp';
import Contact from '../form/Contact';

function Header({ data, linkedin, phone, activitiesList, index }) {
	const [open, setOpen] = useState(false);
	const [openNavMobil, setOpenNavMobil] = useState(false);

	const { nodes } = data.allMarkdownRemark;

	const newActivitiesList = [];

	for (const title of activitiesList) {
		newActivitiesList.push(nodes.find((n) => n.frontmatter.title === title));
	}

	return (
		<>
			<header className="large-contain">
				<div className="header-logo-wrapper">
					{/* {index ? (
						<Link to="/" title="Retour à l'index" label-aria="Retour à l'index de ECEO">
							<LogoAnimation />
						</Link>
					) : ( */}
					<Link to="/" title="Retour à l'index" label-aria="Retour à l'index de ECEO">
						<img src={logo} alt="Retour à l'index de ECEO" />
					</Link>
					{/* )} */}
				</div>
				<div className="header-nav-wrapper">
					<nav className="desktop">
						<ul className="primary-nav">
							<li>
								<Link
									to="/"
									activeClassName="active"
									title="Retour à l'index"
									activeStyle={{ color: '#89a112' }}
								>
									Accueil
								</Link>
							</li>
							<li>
								<Link to="/eceo" title="Découvrez Eceo" activeStyle={{ color: '#89a112' }}>
									ECEO
								</Link>
							</li>
							<li>
								<Link
									to="/l-excellence-operationnelle"
									title="Qu'est ce que l'excellence opérationnelle ?"
									activeStyle={{ color: '#89a112' }}
								>
									L'excellence opérationnelle
								</Link>
							</li>
							<li>
								<button
									type="button"
									onClick={() => {
										setOpen(!open);
									}}
								>
									Domaines d'intervention
									<ChevronIcon open={open} rotate />
								</button>
								{open && (
									<ul className="header-subnav-wrapper secondary-nav">
										{newActivitiesList.map(({ fields, frontmatter }) => (
											<li key={`header_nav_ul_li_${frontmatter.title}`}>
												<Link
													to={`${fields.slug}`}
													title={frontmatter.title}
													activeStyle={{ color: '#89a112' }}
												>
													{frontmatter.title}
												</Link>
											</li>
										))}
									</ul>
								)}
							</li>
							<li>
								<Link to="/blog">Actualités</Link>
							</li>
						</ul>
						<div className="buttons-wrapper">
							<Link to="/contact" className="button back-btn">
								Contact
							</Link>
							<p className="button border-btn">{phone}</p>
						</div>
					</nav>
					<nav className="mobile">
						<HamburgerButton
							openNavMobil={openNavMobil}
							openNavMobilClick={() => {
								setOpenNavMobil(!openNavMobil);
							}}
						/>
					</nav>
				</div>
			</header>
			<div className="header-drawer">
				<Drawer
					linkedin={linkedin}
					phone={phone}
					newActivitiesList={newActivitiesList}
					openNavMobil={openNavMobil}
					openNavMobilClick={() => {
						setOpenNavMobil(!openNavMobil);
					}}
				/>
			</div>
			<style jsx>{`
				header {
					position: relative;
					max-height: 126px;
					padding: 15px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.header-logo-wrapper {
					width: 200px;
				}
				.header-logo-wrapper img {
					width: 200px;
				}
				.desktop {
					display: none;
				}
				.mobile {
					z-index: 9999;
					position: relative;
				}
				.header-drawer {
					display: block;
				}
				li button {
					border: inherit;
					background: inherit;
					font-size: inherit;
					color: inherit;
					font-weight: inherit;
					font-family: inherit;
					padding: 0;
				}
				li button:hover {
					color: #89a112;
				}
				.buttons-wrapper {
					width: 260px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-left: 10px;
				}
				@media (min-width: 1400px) {
					.mobile,
					.header-drawer {
						display: none;
					}
					.header-logo-wrapper {
						width: 360px;
					}
					.header-logo-wrapper img {
						width: 360px;
					}
					.desktop {
						display: flex;
						align-items: center;
					}
				}
			`}</style>
		</>
	);
}

function Drawer({ linkedin, phone, newActivitiesList, openNavMobil, openNavMobilClick }) {
	return (
		<div
			aria-label="Navigation Principale"
			className={`drawer-bkg-black ${openNavMobil ? 'open' : ''}`}
			onClick={openNavMobilClick}
		>
			<div className={`drawer ${openNavMobil ? 'open' : ''}`}>
				<ul className="primary-nav">
					<li>
						<Link
							to="/"
							activeClassName="active"
							title="Retour à l'index"
							activeStyle={{ color: '#89a112' }}
						>
							Accueil
						</Link>
					</li>
					<li>
						<Link to="/eceo" title="Découvrez Eceo" activeStyle={{ color: '#89a112' }}>
							ECEO
						</Link>
					</li>
					<li>
						<Link
							to="/l-excellence-operationnelle"
							title="Qu'est ce que l'excellence opérationnelle ?"
							activeStyle={{ color: '#89a112' }}
						>
							L'excellence opérationnelle
						</Link>
					</li>
					{newActivitiesList.map(({ fields, frontmatter }) => (
						<li key={`header_nav_ul_li_${frontmatter.title}`}>
							<Link
								to={`${fields.slug}`}
								title={frontmatter.title}
								activeStyle={{ color: '#89a112' }}
							>
								{frontmatter.title}
							</Link>
						</li>
					))}
					<li>
						<Link to="/blog">Actualités</Link>
					</li>
				</ul>
				<div className="buttons-wrapper">
					<Link to="/contact" className="button back-btn">
						Contact
					</Link>
					<p className="button border-btn">{phone}</p>
				</div>
			</div>
			<style jsx>
				{`
					.drawer-bkg-black.open {
						background-color: rgba(0, 0, 0, 0.5);
						position: fixed;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						z-index: 490;
					}

					.drawer {
						position: fixed;
						width: 300px;
						min-height: 100vh;
						background: white;
						top: 0;
						z-index: 500;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						left: -300px;
						transition: all 0.2s ease-in-out;
						height: 100%;
					}
					.drawer.open {
						transform: translateX(300px);
						transition: all 0.2s ease-in-out;
					}
					.drawer-button-connexion {
						margin: 25px 0 15px 0;
					}
					ul.primary-nav {
						list-style: none;
						display: flex;
						flex-direction: column;
						align-items: center;
						overflow: auto;
					}
					ul.primary-nav li {
						margin: 15px 0;
					}
					.buttons-wrapper {
						display: flex;
						flex-direction: column;
						margin: 15px 0;
					}
				`}
			</style>
		</div>
	);
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query Header {
				allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "activity-page" } } }) {
					nodes {
						frontmatter {
							title
						}
						fields {
							slug
						}
					}
				}
			}
		`}
		render={(data) => <Header data={data} {...props} />}
	/>
);
